<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import CheckboxToggle from '@/components/form/CheckboxToggle.vue'
import { ChampionshipRegistrationSelectTeamTask } from '@/model/championship/championship-registration-select-team-task'
import { useHttpClient, useHttpGet } from '@/vf'
import { toRefs } from 'vue'
import ChampionshipRegistrationResetButton from './ChampionshipRegistrationResetButton.vue'
import ChampionshipRegistrationTeamList from './ChampionshipRegistrationTeamList.vue'
import { ChampionshipRegistrationApproveData } from './championship-registration'
const props = defineProps<TasksProps<ChampionshipRegistrationSelectTeamTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const { data, refresh } = await useHttpGet<ChampionshipRegistrationApproveData>(
    `/championship/registration/${task.value.organizationId}/select-payment-method/${task.value.id}`,
)
const http = useHttpClient()
/*─────────────────────────────────────┐
│  submit                              │
└─────────────────────────────────────*/
async function submit() {
    await http.post(`/championship/registration/${data.value.registrationId}/select-payment-method`)
    emit("updated")
}
</script>
<template>
    <div class="p-5" v-if="props.viewMode === 'admin'">
        <div class="alert alert-info">
            {{ $t("@tasks:tasks.championship_registration.select_payment_method.waiting_for_customer") }}
        </div>

        <ChampionshipRegistrationTeamList :teams="data.teams" :view-mode="props.viewMode" />
    </div>
    <div class="p-5" v-else>
        <b>{{ $t("@tasks:tasks.championship_registration.select_payment_method.description.title") }}</b>
        <div>{{ $t("@tasks:tasks.championship_registration.select_payment_method.description.text") }}</div>

        <div class="pl-3 mb-5 mt-4">
            <CheckboxToggle>Auswahl von Mollie</CheckboxToggle>
            <CheckboxToggle>Teammitglieder sollen Zahlungsaufforderung erhalten</CheckboxToggle>
        </div>

        <ChampionshipRegistrationTeamList :teams="data.teams" :view-mode="props.viewMode" />

        <div class="d-flex justify-content-between mt-5">
            <div class="flex-grow-1">
                <ChampionshipRegistrationResetButton
                    :registrationId="data.registrationId"
                    @resetted="emit('updated')"
                />
            </div>
            <div>
                <button class="btn btn-primary" @click="submit()">Jetzt bezahlen</button>
            </div>
        </div>
    </div>
</template>
